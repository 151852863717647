import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/layout';
import CallToAction from '../components/call-to-action';

const Row = styled('section')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: calc(100vh - 8rem);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Column = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const ProfileImage = styled(Image)`
  border-radius: 16px;
  box-shadow: 2px 5px 25px 5px rgba(0, 0, 0, 0.4);
  /* margin-left: auto; */
  margin-top: 2rem;
  max-height: 595px;
  max-width: 400px;
  overflow: visible !important;
  transform: translate(-30px, 0);
  width: 100%;

  @media (min-width: 768px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 2px 10px 50px 10px rgba(0, 0, 0, 0.4);
    margin-left: 0;
    margin-top: 0;
    max-height: 800px;
    max-width: 500px;
  }

  img {
    border-radius: 16px;
    margin-top: 0;
    position: relative;

    @media (min-width: 768px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  ::after {
    background-color: #fff;
    content: '';
    display: none;
    left: -4rem;
    padding: 0 4rem;
    position: absolute;
    width: 100%;
    z-index: 1;

    @media (min-width: 768px) {
      bottom: -100px;
      display: block;
      height: 100px;
    }
  }
`;

const Copy = styled('div')`
  margin-top: 2rem;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  p {
    color: #555;
    font-size: 1rem;
    opacity: 0.5;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;

const MainTitle = styled('h1')`
  font-size: 4rem;
  text-transform: uppercase;

  @media (min-width: 992px) {
    font-size: 6rem;
  }

  @media (min-width: 1200px) {
    font-size: 8rem;
  }
`;

const SectionTitle = styled('h2')`
  font-size: 3rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const SectionSubtitle = styled('p')`
  color: #555;
  margin-bottom: 1rem;
  opacity: 0.5;
`;

const Home = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "NorbertKalacska.jpg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout fullWidth={true}>
      <Row>
        <Column
          css={css`
            order: 2;
            padding: 0;

            @media (min-width: 768px) {
              order: 0;
              padding: 0 4rem;
            }
          `}
        >
          <ProfileImage
            fluid={image.sharp.fluid}
            alt="Norbert Kalácska portrait"
            fadeIn
          />
        </Column>
        <Column
          css={css`
            order: 1;

            @media (min-width: 768px) {
              order: 0;
            }
          `}
        >
          <Copy
            css={css`
              align-self: flex-start;
            `}
          >
            <p>I believe</p>
            <MainTitle>
              Together
              <br />
              we
              <br />
              can
            </MainTitle>
          </Copy>
        </Column>
      </Row>
      <Row
        css={css`
          text-align: center;
        `}
      >
        <Column>
          <SectionTitle>Blog</SectionTitle>
          <SectionSubtitle>
            Memoirs and moments of my professional journey.
          </SectionSubtitle>
          <CallToAction to="/blog">Let's check it out</CallToAction>
        </Column>
      </Row>
      <Row
        css={css`
          text-align: center;
        `}
      >
        <Column>
          <SectionTitle>From LEGOs To Software</SectionTitle>
          <SectionSubtitle>
            A story of why and how I've become a developer.
          </SectionSubtitle>
          <CallToAction to="/about">Let's read it</CallToAction>
        </Column>
      </Row>
    </Layout>
  );
};

export default Home;
